
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/es'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(utc)

dayjs.tz.setDefault('America/Santo_Domingo')

// defaultLocale: "es"


dayjs.updateLocale("es")
dayjs.locale('es')


export default dayjs
