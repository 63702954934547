import { StoreProcessType, type Product, type ProductPlan } from '../products'

export const TECH_HOME: Product = {
  productId: 49,
  name: 'Equipo Fijo',
  tagline: 'protege tu equipo',
  code: 'tech-home',
  process: 'PURCHASE',
  bannerUrl: '/img/product-cards/tech-fijo.jpg',
  iconUrl: '/img/product-icons/tech-fijo.png',
  description:
    '¿Te imaginas un día sin tu móvil? Nuestro seguro te brinda cobertura ante cualquier daño físico o del sistema. Además, te incluye la mano de obra para la reparación de tu equipo con los mejores expertos del mercado.',
  compliances: [
    'ROBO Y DANOS',
    'TV',
    'DESKTOP',
    'EQUIPOS DE MUSICA',
    'VIDEO JUEGOS CONSOLA',
    'HOGAR INTELIGENTE (CCTV, GOOGLE HOME, ALEXA, ILUMINACION)',
  ],
  exceptions: [
    'DECUCIBLE DANOS: 10% DE LA PERDIDA (MINIMO RD$5,000)',
    'DEDUCIBLE ROBO: 15% DE LA PERDIDA (MINIMO RD$ 5,000)',
    'PERIODOD DE CARENCIA ROBO: 30 DIAS CALENDARIO',
    'PERIODO DE CARENCIA DANOS: 48 HORAS DESPUES DE LA COMPRA',
  ],
  requirements: ['Marca', 'Modelo ', 'Año', 'Numero de serie', 'Foto del equipo'],
  insurances: ['unit'],
  highlights: [
    {
      title: 'COMPLETO',
      artWork: '/img/product-traits/tech/completo.svg',
      description: ' COBERTURA EN CASO DE QUE SE TE DANE O TE LO ROBEN.',
    },
    {
      title: 'INNOVADOR',
      artWork: '/img/product-traits/tech/innovador.svg',
      description:
        'PROTEGEMOS UNA GRAN VARIEDAD DE EQUIPOS, ASEGURANDO QUE RECUPERES TU INVERSION EN CUALQUIER EVENTO.',
    },
    {
      title: 'FLEXIBLE',
      artWork: '/img/product-traits/tech/flexible.svg',
      description: 'TU ELIGES LOS EQUIPOS QUE QUIERES PROTEGER.',
    },
    {
      title: 'FACIL',
      artWork: '/img/product-traits/tech/facil.svg',
      description: 'ELIGE TU COBERTURAY CONTRATA CON UN PAR DE CLICS EN MINUTOS.',
    },
  ],
  plans: [
    {
      name: 'LITE',
      code: 'LITE',
      description: '',
      highlights: {
        title: 'Especificaciones',
        description: [
          {
            label: 'Cobertura en caso de avería:',
            detail: 'RD$25,000',
          },
        ],
      },
      price: 58,
      subscription: 'monthly',
    },
    {
      name: 'FULL',
      code: 'FULL',
      description: '',
      highlights: {
        title: 'Especificaciones',
        description: [
          {
            label: 'Cobertura en caso de avería:',
            detail: 'RD$50,000',
          },
        ],
      },
      price: 116,
      subscription: 'monthly',
    },
    {
      name: 'ULTRA',
      code: 'ULTRA',
      description: '',
      highlights: {
        title: 'Especificaciones',
        description: [
          {
            label: 'Cobertura en caso de avería:',
            detail: 'RD$100,000',
          },
        ],
      },
      price: 232,
      subscription: 'monthly',
    },
  ],
}
