import { StoreProcessType, type Product, type ProductPlan } from '../products'

export const FUNERAL: Product = {
  productId: 6,
  name: 'Funeraria',
  tagline: 'un gesto de amor y seguridad',
  code: 'funeraria',
  process: 'PURCHASE',
  bannerUrl: '/img/product-cards/funeraria.png',
  iconUrl: '/img/product-icons/funeraria.png',
  description:
    'Este plan está concebido para apoyar a la familia, titular y dependientes del afiliado en casoo de fallecimiento, através de servicios de asistencia integral orientados a proveer tranquilidad durante un momento difícil.',
  compliances: [
    'Apoyo económico en los servicios relacionados a gastos funerarios hasta el límite contratado.',
    'Límite contratado entregado a los beneficiarios o herederos legales.',
    'Gestión de documentación',
    'Sala funeraria',
    'Ataúd',
    'Embalsamiento',
    'Flores',
    'Libro de firmas',
    'Servicios de café',
    'Esquela en prensa',
    'Traslado en carroza fúnebre al cementerio',
    'Traslado de restos a nivel nacional',
    'Sericios religiosos',
    'Lápida',
    'Comestización',
    'Transporte para los acompañantes',
    'Recordatorios',
    'Funeraria de su elección',
  ],
  exceptions: [
    'Fallecimiento causado por enfermedad, lesión o condición pre-existente.',
    'Suicidio dentro de los primeros seis (6) meses dentro de la cobertura de cada persona asegurada.',
    'Si la persona asegurada fallece por cause de guerra, duelos, cualquier acto notoriamente peligroso o delictivo o por violación de la ley.',
    'Abuso de sustancias alcohólicas o drogas.',
  ],
  requirements: [
    'Edad minimo de ingreso: 18 años.',
    'Edad maxima de ingreso: 65 años.',
    'Edad maxima de permanencia: 75 años.',
  ],
  insurances: ['humano'],
  highlights: [
    {
      title: 'Inhumación',
      artWork: '/img/product-traits/funeral/lapida.svg',
      description: 'Inhumación en nicho o sarcófago.',
    },
    {
      title: 'Transporte',
      artWork: '/img/product-traits/funeral/transporte.svg',
      description: 'Traslado del fallecido dentro del territorio nacional.',
    },
    {
      title: 'Arreglo floral',
      artWork: '/img/product-traits/funeral/flores.svg',
      description: 'Flores, recordatorios y libro de firmas.',
    },
    {
      title: 'Lápida',
      artWork: '/img/product-traits/funeral/lapida.svg',
      description: 'Lápida o cruz.',
    },
  ],
  plans: [
    {
      name: 'MORADO',
      code: 'MORADO',
      description: '',
      highlights: {
        description: [
          {
            label: 'FALLECIMIENTO:',
            detail: 'RD$100,000',
          },
        ],
      },
      price: 23,
      subscription: 'monthly',
    },
    {
      name: 'PLATINUM',
      code: 'platinum',
      description: '',
      highlights: {
        description: [
          {
            label: 'FALLECIMIENTO:',
            detail: 'RD$150,000',
          },
        ],
      },
      price: 34.0,
      subscription: 'monthly',
    },
    {
      name: 'BLACK',
      code: 'BLACK',
      description: '',
      highlights: {
        description: [
          {
            label: 'FALLECIMIENTO:',
            detail: 'RD$200,000',
          },
        ],
      },
      price: 46.0,
      subscription: 'monthly',
    },
  ],
}
