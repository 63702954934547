export default defineNuxtRouteMiddleware(async (to, from) => {
  //const { clear, loggedIn } = useUserSession()
  //if (loggedIn.value) {
  //  try {
  //    const userData: ContigoMarketplaceApiSchema<'UserResponseModel'> = await $fetch(
  //      '/api/__proxy/manage/info',
  //      {
  //        method: 'GET',
  //      }
  //    )
  //    if (!userData.id) clear()
  //  } catch {
  //    clear()
  //  }
  //}
})
