import revive_payload_client_b6oj13tKfF from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_myc5lC6DNq from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JTvS2FrVry from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ExQ0nq6wBV from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_sass@1.78._7g26izov3zm3wgzh5uo4bzhigu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import nuxt_plugin_xQb6UiPNet from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-open-fetch@0.9.1_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4/node_modules/nuxt-open-fetch/dist/runtime/nuxt-plugin.mjs";
import payload_client_EJVyQk3jbv from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XswbcBIVdu from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jPRzrtV90W from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6sfVd21ACf from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_t1fJABqaL6 from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_iryt4a5ewvz7qrubn4q62ehlym/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_0YRlxiQjPP from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-viewport@2.1.6_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_bb3FJ1NUVA from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.21.2/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_WBsdITU0tO from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.0.5_@babel+parser@7.25.6_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_6iBUJmtEkA from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/@nuxtjs+harlem@1.3.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/@nuxtjs/harlem/dist/runtime/plugin.mjs";
import session_client_whTjebqhCT from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/node_modules/.pnpm/nuxt-auth-utils@0.3.6_magicast@0.3.5_rollup@4.21.2/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import primevue_7rYYRZQLyx from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/plugins/primevue.ts";
export default [
  revive_payload_client_b6oj13tKfF,
  unhead_myc5lC6DNq,
  router_JTvS2FrVry,
  _0_siteConfig_ExQ0nq6wBV,
  nuxt_plugin_xQb6UiPNet,
  payload_client_EJVyQk3jbv,
  navigation_repaint_client_XswbcBIVdu,
  check_outdated_build_client_jPRzrtV90W,
  chunk_reload_client_6sfVd21ACf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_t1fJABqaL6,
  plugin_client_0YRlxiQjPP,
  plugin_bb3FJ1NUVA,
  primevue_plugin_egKpok8Auk,
  plugin_client_WBsdITU0tO,
  plugin_6iBUJmtEkA,
  session_client_whTjebqhCT,
  primevue_7rYYRZQLyx
]